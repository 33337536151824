import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import useFirebase from '../../use-firebase';
import * as utils from '../../utils';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';

interface IState {
  tenantInfo: utils.ITenantInfo | null;
  loading: boolean;
  errorMessage: string;
  inputError: boolean;
  canContinue: boolean;
  isButtonLoading: boolean;
  email: string;
  password: string;
  showPassword: boolean;
  showPasswordField: boolean;
  showForgotPasswordScreen: boolean;
  isAlreadySignedIn: boolean;
  wholePageLoading: boolean;
}

const Login = () => {
  const classes = useStyles();
  const firebaseApp = useFirebase();
  const [state, setState] = useState<IState>({
    loading: true,
    errorMessage: '',
    tenantInfo: null,
    inputError: false,
    canContinue: false,
    isButtonLoading: false,
    email: '',
    password: '',
    showPassword: false,
    showPasswordField: false,
    showForgotPasswordScreen: false,
    isAlreadySignedIn: false,
    wholePageLoading: false,
  });

  // handle initial auth state on component mount
  useEffect(() => {
    const getFirebase = async () => {
      if (firebaseApp) {
        const auth = firebaseApp.auth();
        const url = new URLSearchParams(window.location.search);

        // handle signing out
        if (url.get('signout')) {
          await logout();
          url.delete('signout');
          resetSearchParams(url);
          // safari doesn't log out initially, so hack it to log out again
          navigator.vendor.includes('Apple') &&
            sessionStorage.setItem('should-logout', 'true');

          // if signing out with ?signout=1, make sure to still register onAuthStateChanged()
          // this is needed to handle immediately signing back in as a password user after logging out
          auth.onAuthStateChanged(handleAuthState);
          await auth.getRedirectResult();
          return;
        }
        // update auth state
        auth.onAuthStateChanged(handleAuthState);
        await auth.getRedirectResult();
      }
    };
    getFirebase();
  }, [firebaseApp]);

  // handle automatic password reset by evaluating query params
  useEffect(() => {
    const executeAutomaticPasswordReset = async () => {
      if (firebaseApp) {
        const url = new URLSearchParams(window.location.search);
        const email = url.get('email');
        const tenantId = url.get('tenantId');
        const passwordReset = url.get('passwordReset') === 'true';

        if (email && passwordReset && tenantId) {
          setState(prev => ({
            ...prev,
            email,
            wholePageLoading: true,
          }));

          // allow handleForgotPasswordClick to perform additional logic
          // Note: because `setState` is so slow, use `emailOverride` to more quickly call function with a non-empty email
          await handleForgotPasswordClick({
            emailOverride: email,
            tenantOverride: tenantId,
          });

          setState(prev => ({
            ...prev,
            wholePageLoading: false,
          }));

          // cleanup
          url.delete('email');
          url.delete('passwordReset');
          url.delete('tenantId');
          resetSearchParams(url);
        }
      }
    };
    executeAutomaticPasswordReset();
  }, [firebaseApp]);

  const resetSearchParams = (url: URLSearchParams) => {
    const newSearchParams = url.toString() ? `?${url.toString()}` : '';
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(
      null,
      '',
      `${document.location.origin}${newSearchParams}`,
    );
  };

  const handleAuthState = async (user: firebase.User | null) => {
    const shouldLogout = sessionStorage.getItem('should-logout');
    if (!user) {
      const userFromCookie = utils.getFirebaseUserCookie();
      if (userFromCookie && !shouldLogout) {
        // @ts-ignore
        const firebaseUser = new firebase.User(
          userFromCookie,
          userFromCookie.stsTokenManager,
          userFromCookie,
        );
        const auth = firebase.auth();
        auth.tenantId = firebaseUser.tenantId;
        await auth.updateCurrentUser(firebaseUser);
        return;
      }
      utils.storeRedirectInfo();
      await utils.handleUserLoggedIn(null);
      const { email, tenantDomain, provider } = utils.getUserFromUrl();
      if (email || tenantDomain) {
        // skip to next screen (sso, password) if email or tenant is provided in url
        // email or tenant must be provided for provider to be a valid option
        handleEmailUpdate(null, email);
        await handleFetchTenantAndContinue(null, email, tenantDomain, provider);
      }
      setState(prev => ({
        ...prev,
        loading: false,
        isButtonLoading: false,
        isAlreadySignedIn: false,
      }));
      return;
    }
    if (shouldLogout) {
      sessionStorage.removeItem('should-logout');
      await logout();
      return;
    }
    utils.storeFirebaseUserCookie(user);
    utils.storeRedirectInfo();
    await utils.handleUserLoggedIn(user);
    setState(prev => ({
      ...prev,
      isAlreadySignedIn: true,
    }));
  };

  const logout = async () => {
    setState(prev => ({
      ...prev,
      isButtonLoading: true,
      showPasswordField: false,
      password: '',
    }));
    utils.expireFirebaseUserCookie();
    await firebaseApp!.auth().signOut();
    await handleAuthState(null);
  };

  const handleEmailUpdate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
    email?: string,
  ) => {
    const value = event?.currentTarget?.value || email || '';
    const isValid = utils.isEmailValid(value.toLowerCase());
    setState(prev => ({
      ...prev,
      email: value,
      canContinue: isValid,
      inputError: email ? !isValid : false,
    }));
  };

  const handleFetchTenantAndContinue = async (
    event: FormEvent | null,
    email?: string,
    tenantDomain?: string,
    providerName?: string,
  ) => {
    event && event.preventDefault();
    setState(prev => ({ ...prev, isButtonLoading: true }));
    try {
      const emailComponents = email ? email.split('@') : state.email.split('@');
      let tenantInfo: utils.ITenantInfo | null = null;
      if (emailComponents.length === 2 || tenantDomain) {
        // email gets priority over tenant
        const domain =
          emailComponents.length === 2 ? emailComponents[1] : tenantDomain;
        tenantInfo = await utils.lookupTenant(domain || '');
      }
      if (!tenantInfo) {
        setState(prev => ({
          ...prev,
          inputError: true,
          isButtonLoading: false,
        }));
        return;
      }
      firebaseApp!.auth().tenantId = tenantInfo!.tenantId;
      setState(prev => ({ ...prev, tenantInfo }));
      let hasPassword = false;
      let providerString = providerName || '';
      if (!providerString) {
        tenantInfo!.providers.forEach(({ name }) => {
          if (name === 'password') {
            hasPassword = true;
            return;
          }
          // NOTE: this assumes there is only one non password provider,
          // will need to tweak designs to allow multiple
          providerString = name;
        });
      }

      const auth = firebaseApp!.auth();

      // logging in with redirect is broken for web apps using localhost, or the login app running locally on localhost
      // therefore we need to use signInWithPopup for local development
      // https://github.com/firebase/firebase-js-sdk/issues/7342
      const searchParams = new URLSearchParams(window.location.search);

      const isLocalhost =
        window.location.href.includes('localhost') || // true if running login app locally
        searchParams.get('redirect_uri')?.includes('localhost'); // true if accessing login.brainos.dev from a local web app

      if (providerString.startsWith('saml')) {
        const provider = new firebase.auth.SAMLAuthProvider(providerString);
        isLocalhost
          ? await auth.signInWithPopup(provider)
          : await auth.signInWithRedirect(provider);
        return;
      }
      if (providerString.startsWith('okta')) {
        const provider = new firebase.auth.OAuthProvider(providerString);
        isLocalhost
          ? await auth.signInWithPopup(provider)
          : await auth.signInWithRedirect(provider);
        return;
      }
      if (hasPassword) {
        setState(prev => ({
          ...prev,
          isButtonLoading: false,
          showPasswordField: true,
        }));
        return;
      }
      // else there is no password or providers so show error message
      setState(prev => ({
        ...prev,
        isButtonLoading: false,
        inputError: true,
      }));
    } catch (err) {
      console.error(err);
      setState(prev => ({
        ...prev,
        isButtonLoading: false,
        inputError: true,
      }));
    }
  };

  const handlePasswordUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setState(prev => ({ ...prev, password: value, inputError: false }));
  };

  const handlePasswordSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setState(prev => ({ ...prev, isButtonLoading: true }));
    const auth = firebaseApp!.auth();
    if (state.tenantInfo) {
      auth.tenantId = state.tenantInfo.tenantId;
    }
    try {
      await auth.signInWithEmailAndPassword(state.email, state.password);
      setState(prev => ({
        ...prev,
        isButtonLoading: false,
        isAlreadySignedIn: true,
        loading: true,
      }));
    } catch (e) {
      setState(prev => ({
        ...prev,
        isButtonLoading: false,
        inputError: true,
      }));
    }
  };

  // Note: because `setState` is so slow, use `emailOverride` and `tenantOverride` to more quickly call function with a non-empty email
  const handleForgotPasswordClick = async ({
    emailOverride,
    tenantOverride,
  }: {
    emailOverride?: string;
    tenantOverride?: string;
  }) => {
    setState(prev => ({ ...prev, isButtonLoading: true }));
    const auth = firebaseApp!.auth();
    auth.tenantId = tenantOverride ?? state.tenantInfo?.tenantId ?? null;
    try {
      await auth.sendPasswordResetEmail(emailOverride ?? state.email);
      setState(prev => ({
        ...prev,
        isButtonLoading: false,
        errorMessage: '',
        showForgotPasswordScreen: true,
      }));
      // when icon was being rendered, it would push the scroll position down
      // use scrollTo to compensate for this
      window.scrollTo(0, 0);
    } catch (e: any) {
      if (e.code === 'auth/user-not-found') {
        setState(prev => ({
          ...prev,
          isButtonLoading: false,
          errorMessage: '',
          showForgotPasswordScreen: true,
        }));
        return;
      }
      setState(prev => ({
        ...prev,
        isButtonLoading: false,
        errorMessage: e.message,
      }));
    }
  };

  const RoundedButton = withStyles(() => ({
    root: {
      backgroundColor: '#F47521',
      color: '#FFFFFF',
      textTransform: 'none',
      width: 335,
      borderRadius: 21.5,
      '&:disabled': {
        color: '#FFFFFF',
      },
      '&:hover': {
        backgroundColor: 'rgba(255,119,33,0.76)',
      },
    },
  }))(Button);

  const signInOrContinue = state.showPasswordField ? 'Sign In' : 'Continue';
  const signInOrPassword = state.showPasswordField ? 'Password' : 'Sign In';

  const renderLoginForm = () => {
    return (
      <form
        onSubmit={
          state.showPasswordField
            ? handlePasswordSubmit
            : handleFetchTenantAndContinue
        }
        className={
          state.showPasswordField ? classes.passwordForm : classes.emailForm
        }
      >
        <TextField
          variant="outlined"
          className={
            state.showPasswordField ? classes.hidden : classes.emailTextField
          }
          id="email"
          type="email"
          name="email"
          label="Email Address"
          aria-label="email"
          autoComplete="username"
          value={state.email}
          onChange={handleEmailUpdate}
          error={state.inputError}
          autoFocus
          helperText={
            state.inputError && (
              <span className={classes.helperMessage}>
                <ErrorOutline className={classes.errorIcon} /> Couldn't find
                your Brain Account
              </span>
            )
          }
        />
        <TextField
          variant="outlined"
          className={
            state.showPasswordField ? classes.passwordTextField : classes.hidden
          }
          id="password"
          label="Password"
          name="password"
          aria-label="password"
          autoComplete="current-password"
          type={state.showPassword ? 'text' : 'password'}
          onChange={handlePasswordUpdate}
          value={state.password}
          error={state.inputError}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setState(prev => ({
                      ...prev,
                      showPassword: !prev.showPassword,
                    }));
                  }}
                  edge="end"
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={
            state.inputError && (
              <span className={classes.helperMessage}>
                <ErrorOutline className={classes.errorIcon} /> Wrong Password.
                Try again.
              </span>
            )
          }
        />
        <br />
        <RoundedButton
          variant="contained"
          name="signInOrContinue"
          disabled={
            state.isButtonLoading ||
            state.inputError ||
            (state.showPasswordField ? !state.password : !state.canContinue)
          }
          className={classes.stickyButton}
          type="submit"
        >
          {state.isButtonLoading ? '' : signInOrContinue}
        </RoundedButton>
        {state.isButtonLoading && (
          <CircularProgress size={24} className={classes.buttonLoader} />
        )}
      </form>
    );
  };

  const isCli = window.sessionStorage.getItem('login_type') === 'cli';
  const isSignedInViaCli = isCli && state.isAlreadySignedIn;
  const renderSignedInText = (): string => {
    if (isSignedInViaCli) {
      return 'Login complete, you can now close this page.';
    }
    if (state.isAlreadySignedIn) {
      return 'Redirecting to app...';
    }
    return '';
  };
  const loginForm = state.loading ? (
    <>
      <div
        className={isSignedInViaCli ? classes.closePage : classes.redirecting}
      >
        {renderSignedInText()}
      </div>
      {!isCli && state.isAlreadySignedIn && <CircularProgress />}
    </>
  ) : (
    renderLoginForm()
  );

  return (
    <div className={classes.container}>
      {state.wholePageLoading ? (
        <div className={classes.app}>
          <img
            src="/brainLogo.svg"
            className={classes.brainLogo}
            alt="Brain Corp"
          />
          <div style={{ marginTop: 20 }}>
            <CircularProgress
              size={75}
              classes={{ circle: classes.indeterminateCircleSvg }}
            />
          </div>
        </div>
      ) : (
        <>
          {state.showForgotPasswordScreen ? (
            <div className={classes.app}>
              <img
                src="/emailIcon.svg"
                className={classes.emailIcon}
                alt="email icon"
              />
              <h1 className={classes.title}>Please Check Your Email</h1>
              <div className={classes.forgotBlurb}>
                If an account exists for <strong>{state.email}</strong>, you
                will receive an email with instructions on how to reset your
                password. Please be sure to check your spam folder. If you do
                not receive an email please contact your administrator.
              </div>
              <RoundedButton
                name="backToSignIn"
                className={classes.button}
                onClick={() =>
                  setState(prev => ({
                    ...prev,
                    showForgotPasswordScreen: false,
                  }))
                }
              >
                Back to Sign In
              </RoundedButton>
            </div>
          ) : (
            <div className={classes.app}>
              {!state.showPasswordField || state.isAlreadySignedIn ? (
                <img
                  src="/brainLogo.svg"
                  className={classes.brainLogo}
                  alt="Brain Corp"
                />
              ) : (
                <div className={classes.brainLogo} />
              )}
              <h1 id="signInOrPassword" className={classes.title}>
                {state.isAlreadySignedIn ? '' : signInOrPassword}
              </h1>
              {state.showPasswordField && !state.isAlreadySignedIn && (
                <span>{state.email}</span>
              )}
              {state.errorMessage && (
                <>
                  <br />
                  <span className={classes.error}>{state.errorMessage}</span>
                </>
              )}
              {loginForm}
              {state.showPasswordField && !state.isAlreadySignedIn && (
                <>
                  <Button
                    name="forgotPassword"
                    className={classes.forgotPassword}
                    onClick={() => handleForgotPasswordClick({})}
                    disabled={state.isButtonLoading}
                  >
                    {state.isButtonLoading ? '' : 'Forgot Password?'}
                  </Button>
                  {state.isButtonLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonLoader}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}

      <div className={classes.footer}>
        <a
          href="https://www.braincorp.com/data-privacy/"
          target="_blank"
          rel="noreferrer"
          className={classes.privacy}
        >
          Privacy
        </a>
        <span className={classes.trademark}>
          © {new Date().getFullYear()} Brain Corporation. All rights reserved.
        </span>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  app: {
    [theme.breakpoints.up('lg')]: {
      margin: '20vh auto 0 auto',
      height: 443,
      maxWidth: 612,
      boxShadow: '0 24px 37px 1px rgba(0,0,0,0.1)',
    },
    [theme.breakpoints.down('md')]: {
      margin: '-50px 0 0 -7px',
      height: '108vh',
      width: '100vw',
    },
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
  },
  hidden: {
    display: 'none',
  },
  brainLogo: {
    paddingTop: 70,
    height: 43,
    width: 107,
  },
  emailIcon: {
    paddingTop: 70,
    height: 88,
    width: 88,
  },
  title: {
    color: '#212121',
    fontWeight: 'bold',
    fontSize: 22,
  },
  emailForm: {
    position: 'relative',
    height: 180,
    width: 335,
    margin: '0 auto',
  },
  passwordForm: {
    position: 'relative',
    height: 159,
    width: 335,
    margin: '0 auto',
  },
  emailTextField: {
    marginTop: 35,
    width: 335,
  },
  passwordTextField: {
    marginTop: 15,
    width: 335,
  },
  stickyButton: {
    marginTop: 30,
    height: 45,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  buttonLoader: {
    position: 'absolute',
    top: '81%',
    left: '47%',
    color: '#FFFFFF',
  },
  helperMessage: {
    marginLeft: -15,
    marginTop: -6,
  },
  errorIcon: {
    position: 'relative',
    top: 7,
  },
  error: {
    color: 'red',
  },
  forgotPassword: {
    marginTop: 12,
    textTransform: 'none',
    color: '#0083AF',
    fontSize: 15,
  },
  button: {
    marginTop: 50,
    height: 45,
  },
  forgotBlurb: {
    lineHeight: '26px',
    paddingLeft: 5,
    paddingRight: 5,
  },
  redirecting: {
    textAlign: 'center',
    height: 20,
    marginBottom: 20,
  },
  closePage: {
    marginTop: 100,
    textAlign: 'center',
  },
  footer: {
    marginTop: 24,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      display: 'none', // clicking on new link in webview is bad, privacy policy can be found within app
    },
  },
  privacy: {
    fontWeight: 500,
    color: '#0083AF',
    lineHeight: '16px',
    textDecoration: 'none',
  },
  trademark: {
    marginTop: 15,
    display: 'block',
  },
  indeterminateCircleSvg: {
    color: '#FF9B00',
  },
}));

export default Login;
